import React from 'react';

const Logo = ({ type }) => {

  const getImageName = () => {
    switch (type) {
      case Logo.types.WHITE: 
        return 'logo-white.svg';
      default:
        return 'logo.svg';
    }
  }

  return (
    <a className="c-logo" href="/search">
      <img src={`/IMG/${getImageName()}`} className="c-logo__image" />
    </a>
  )
};

Logo.types = {
  BLUE: "BLUE",
  WHITE: "WHITE"
}

export default Logo;
