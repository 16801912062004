import React, { useState } from 'react';
import {connect} from 'react-redux';

import classes from '../../utils/classes';
import Section from '../section/section';
import Search from '../search/search';
import Logo from '../logo/logo';
import CartButton from '../cart-button/cart-button';
import navData from './navData';
import { getCurrenciesNavData, renderListItem } from './utils';
import NavButton from '../nav-button/nav-button';
import registeredUserRightNavData from './registeredUserRightNavData';
import guestRightNavData from './guestRightNavData';
import loadingRightNavData from './loadingRightNavData';
import currencies from "../../constants/currencies";

const NavHeader = ({ 
  globals, 
  userInfo, 
  isLoading
}) => {
  const [activeNav, setActiveNav] = useState(false);

  const blockClasses = () => classes(
    [
      'c-nav-header',
      {
        'is-active': activeNav
      }
    ],
    globals
  );

  return (
    <header className={blockClasses()}>
      <div className="c-nav-header__navigation-background" onClick={() => setActiveNav(false)}></div>
      <Section globals="g-fullHeight">
        <div className="c-nav-header__body">
          <div className="c-nav-header__cell c-nav-header__cell--nav-button">
            <NavButton active={activeNav} setActive={setActiveNav} />
          </div>
          <div className="c-nav-header__cell c-nav-header__cell--navigation">
            <div className="c-nav-header__mobile-nav-header">
              <Logo type={Logo.types.WHITE} />
            </div>
            <div className="c-nav-header__mobile-nav-subheader">
              <NavButton active={true} setActive={setActiveNav} />
            </div>
            <div className="c-nav-header__mobile-nav-body">
              <ul className="c-nav-header__list c-nav-header__list--navigation">
                {navData.map(item => renderListItem(item))}
              </ul>
            </div>
          </div>
          <div className="c-nav-header__cell c-nav-header__cell--center g-pl10">
            <Search />
          </div>
          <div className="c-nav-header__cell">
            <ul className="c-nav-header__list">
              {isLoading
                ? loadingRightNavData.map(item => renderListItem(item))
                : userInfo.userName !== "Guest"
                  ? registeredUserRightNavData.map(item => renderListItem(item))
                  : guestRightNavData.map(item => renderListItem(item))
              }
            </ul>
            {userInfo?.currency || userInfo?.currency >= 0
              ? (
                <ul className="c-nav-header__list">
                  {getCurrenciesNavData(currencies[userInfo.currency].abbr).map(item => renderListItem(item))}
                </ul>
              )
              : null
            }
            <CartButton fullHeight />
          </div>
        </div>
      </Section>
    </header>
  )
}

const mapStateToProps = ({ user: { isLoading, userInfo }}) => ({
  isLoading,
  userInfo
});

export default connect(mapStateToProps)(NavHeader);