import React from 'react';

import classes from '../../utils/classes';

const NavButton = ({ globals, active, setActive }) => {

  const blockClasses = () => classes(
    [
      'c-nav-button',
      {
        'is-active': active
      }
    ],
    globals
  );

  return (
    <div className={blockClasses()} onClick={() => setActive(prev => !prev)}>
      <div className="c-nav-button__line"></div>
      <div className="c-nav-button__line"></div>
      <div className="c-nav-button__line"></div>
    </div>
  )
}

export default NavButton;