import events from "../constants/events";

const addReduxListener = eventsList => {
  window.addEventListener(events.REDUX, e => {
    eventsList.forEach(eventItem => {
      if (eventItem[0] === e.detail.action) {
        eventItem[1](e.detail.payload);
      }
    });
  }, false);
}

export default addReduxListener;