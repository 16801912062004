import React, { useEffect } from 'react';

import classes from '../../utils/classes';

const Info = ({ text, globals, theme }) => {

  const blockClasses = () => classes(
    [
      'c-info', 
      { 
        [`c-info--theme--${theme}`]: theme,
      }
    ],
    globals
  );

  useEffect(() => {
    console.log('mount the component');
  }, []);

  return (
    <div className={blockClasses()}>
      {text || "Enamine Store"}
    </div>
  )
};

export default Info;
