import isProd from '../../utils/isProd';

const navData = [
  {
    title: "Search",
    href: "/search"
  },
  {
    title: "Products",
    subItems: [
      {
        title: "Building Blocks",
        href: "/products/building-blocks"
      },
      {
        title: "Featured Building Blocks",
        href: "/compoundsets"
      },
      {
        title: "Fragments (Ro3)",
        href: "https://enamine.net/fragments"
      },
      {
        title: "Screening Compounds",
        href: "/products/screening-compounds"
      },
      {
        title: "REAL compounds",
        href: "/products/real-compounds"
      },
      {
        title: "Bioreference Compounds",
        href: "https://enamine.net/hit-finding/compound-collections/bioreference-compounds"
      },
    ]
  },
  {
    title: "eCommerce",
    href: "/ecommerce",
    subItems: [
      {
        title: "PunchOut Integration",
        href: "/ecommerce/punchout"
      },
      {
        title: "e-Catalog (via “Live Link”)",
        href: "/ecommerce/livelink"
      },
      {
        title: "EnamineStore API",
        href: "/ecommerce/api"
      },
    ]
  },
  {
    title: "Support",
    subItems: [
      {
        title: "Billing",
        href: "/billing"
      },
      {
        title: "SDS & CoA",
        href: "/support"
      },
      {
        title: "Web support",
        href: "https://enamine.atlassian.net/servicedesk/customer/portal/23",
        target: "_blank"
      },
      {
        title: "FAQ",
        href: "/about/faq"
      },
      {
        title: "Terms of Use",
        href: "/termsandconditions"
      },
      {
        title: "Privacy Policy",
        href: "/privacypolicy"
      },
      {
        title: "Contacts",
        href: "/contacts"
      },
      {
        title: "Subscribe",
        href: "https://eepurl.com/deKgiD"
      },
    ]
  }
];

if (!isProd()) {
  navData[1].subItems.unshift({
    title: "One Product Page",
    href: "/catalog/EN300-64885"
  })
}

export default navData;