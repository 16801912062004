const isProductPage = () => {

  let pathname = window.location.pathname;

  if (pathname[0] == '/') {
    pathname = pathname.slice(1);
  }

  let pathnameArr = pathname.split('/');

  if (pathnameArr.length == 2) {
    if (
      pathnameArr[0] == 'catalog' && 
      pathnameArr[1].length >= 5 && 
      pathnameArr[1].length <= 100
    ) {
      return true;
    }
  }

  return false;
}

export default isProductPage;