import navHeaderActions from '../../constants/navHeaderActions';

const guestRightNavData = [
  {
    title: "Login",
    action: navHeaderActions.SHOW_LOGIN,
    id: "t-login-button",
    subItems: [
      {
        title: "Registration",
        action: navHeaderActions.SHOW_REGISTER,
        id: "t-registration-button",
      },
    ]
  },
];

export default guestRightNavData;