import amplitude from 'amplitude-js';

import events from "../constants/events";
import config from '../config';

const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init(config.amplitude.apiKey);


const addAmplitudeListener = () => {
  window.addEventListener(events.AMPLITUDE, e => {
    const { type, payload } = e.detail;

    amplitudeInstance.logEvent(type, payload);
  }, false);
}

export default addAmplitudeListener;