import React from 'react';

import navHeaderActions from '../../constants/navHeaderActions';
import classes from '../../utils/classes';

const renderListItem = ({ subItems, href, title, action, id }) => {
  let handleAction = null;

  if (action) {
    handleAction = getAction(action);
  }

  const controlClasses = () => classes([
    'c-nav-header__control',
    {
      [`c-nav-header__control--extended`]: subItems,
      'c-nav-header__control--action': handleAction,
    }
  ]);

  return (
    <li className="c-nav-header__list-item" key={title}>
      {renderControl({href, controlClasses, title, handleAction, id})}
      {subItems && renderSubItems(subItems)}
    </li>
  );
}

const getAction = actionName => {
  switch (actionName) {
    case navHeaderActions.SHOW_LOGIN:
      return () => {
        window.showLogin();
      }
    case navHeaderActions.SHOW_REGISTER:
      return () => {
        window.showRegisterDialog($('minRegDialog'),$('mailVal'),$('regSubm'), $('emailValid'));;
      }
    case navHeaderActions.SHOW_SAVED_LIST:
      return () => {
        document.getElementById('SavedLists').click();
      }
    default: 
      return () => {
        console.log('Default action from utils.js');
      }
  }
}

const renderControl = ({href, controlClasses, title, handleAction, id}) => (
  <a className={controlClasses()} href={href} onClick={handleAction} id={id}>
    <span>{title}</span>
  </a>
);

const renderSubItems = items => (
  <ul className="c-nav-header__sub-list">
    {items.map(({href, title, action, id, target}, idx) => (
      <li className="c-nav-header__sub-list-item" key={idx}>
        <a className="c-nav-header__sub-list-control" href={href} target={target} onClick={getAction(action)} id={id}>{title}</a>
      </li>
    ))}
  </ul>
);

const getCurrenciesNavData = currentCurrencyAbbr => {

  const currencyUrlToChange = window.getChangeCurrencyBase();

  return [
    {
      title: currentCurrencyAbbr,
      subItems: [
        {
          title: "EUR",
          href: `${currencyUrlToChange}EUR`
        },
        {
          title: "USD",
          href: `${currencyUrlToChange}USD`
        },
      ]
    },
  ]
}

export {
  renderListItem,
  renderSubItems,
  getAction,
  getCurrenciesNavData,
}