import React, { useState } from 'react';

import classes from '../../utils/classes';
import withDomain from '../../utils/withDomain';

const Search = ({ globals }) => {
  const [includeChecked, setIncludeChecked] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const blockClasses = () => classes(
    [
      'c-search'
    ],
    globals
  );

  const checkboxButtonClasses = () => classes(
    [
      'c-search__checkbox-button',
      {
        'is-checked': includeChecked
      }
    ],
  );

  const handleCheckboxClick = () => {
    setIncludeChecked(prev => !prev);

    // SLC-2914
    // Toggle "include stereosystems checkbox"
    let includeCheckbox = document.querySelector('#allByRootBorder');
    includeCheckbox.checked = !includeChecked;
  }

  const handleSearchChange = ({ target: { value } }) => {
    setInputValue(value);

    // SLC-2914
    // Set search input value when change actual search
    let oldSearchInput = document.querySelector('#dataSearch');
    oldSearchInput.value = value;
  }

  // SLC-2914
  // Dispatch button click for old search
  const handleSearchClick = () => {
    let click_event = new CustomEvent('click');
    let btn_element = document.querySelector('#searchiddata').getElementsByTagName('button')[0]; 
    btn_element.dispatchEvent(click_event);
  }

  const handleSubmit = e => {
    e.preventDefault();

    handleSearchClick();
  }

  return (
    <div className={blockClasses()}>
      <form onSubmit={handleSubmit}>
        <div className="c-search__body">
          
          <div className="c-search__checkbox-wrapper">
            <div className={checkboxButtonClasses()} onClick={handleCheckboxClick}>
              +
              <input type="checkbox" readOnly checked={includeChecked} hidden />
            </div>
            <div className="c-search__checkbox-info-popper">
              Include stereoisomers, tautomers and salts in search results
            </div>
          </div>
          
          <input
            className="c-search__input"
            type="text"
            value={inputValue}
            placeholder="CAS/MFCD/CatalogID"
            onChange={handleSearchChange}
          />
          <button 
            type="button" 
            className="c-search__button"
            onClick={handleSearchClick}
          >
            <img className="c-search__button-image" src={withDomain("/IMG/search-icon.png")} />
          </button>
        </div>
      </form>
    </div>
  )
}

export default Search;