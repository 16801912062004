import React, { useEffect } from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import Cookies from 'js-cookie';

import { increaseCartCount, setCartCount } from '../../redux/cart/cart.actions';
import { setUserLoading, setUserInfo, setUserCurrency } from '../../redux/user/user.actions';
import { setInfo } from '../../redux/info/info.actions';

import addReduxListener from '../../utils/addReduxListener';
import addAmplitudeListener from '../../utils/addAmplitudeListener';
import removeReduxListener from '../../utils/removeReduxListener';
import { isBillingPage, isOrderDetailsPage, isProductPage, isResetPasswordPage } from '../../utils';

import Logo from '../logo/logo';
import Section from '../section/section';

import currencies from '../../constants/currencies';

const NEW_SITE_HREF = process.env.NEW_SITE_HREF;
const OLD_SITE_COOKIE_DOMAIN = process.env.OLD_SITE_COOKIE_DOMAIN;

const Header = ({
  userInfo,
  isLoading,
  setUserInfo,
  setUserLoading,
  setCartCount,
  increaseCartCount,
  setUserCurrency,
  setInfo,
}) => {

  const fetchUser = () => {
    let userInfoData = null;
    setUserLoading(true);

    axios.get('/userapi:info')
      .then(res => {
        userInfoData = res.data;
        setUserCurrency(currencies[userInfoData.currency] || currencies[0]);
        setUserInfo(userInfoData);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        setUserLoading(false);
      });
  }

  const fetchCartInfo = () => {
    axios.get('/cartapi:info')
      .then(res => {
        const data = res.data;
        setCartCount(data.count);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        
      });
  }

  useEffect(() => {
    if (Cookies.get('redirect')) {
      if (
        isResetPasswordPage() || 
        isOrderDetailsPage() || 
        isBillingPage()
      ) {
        Cookies.remove('redirect', { domain: OLD_SITE_COOKIE_DOMAIN });
      } else if (isProductPage()) {
        window.location.replace(NEW_SITE_HREF + window.location.pathname);
      } else {
        window.location.replace(NEW_SITE_HREF);
      }
    }

    addReduxListener([
      ['setUserInfo', setUserInfo],
      ['setUserLoading', setUserLoading],
      ['increaseCartCount', increaseCartCount],
      ['setCartCount', setCartCount],
      ['setInfo', setInfo],
    ]);
    addAmplitudeListener();

    fetchUser();
    fetchCartInfo();

    return () => {
      removeReduxListener();
    }
  }, []);

  const renderDiscount = ({ discountBB, discountSC }) => (
    <p className="c-header__discount-info">
      { discountBB
        ?
          <>
            Your discount for Building Blocks is <b>{discountBB}%</b>
          </>
        : null
      }
      { discountSC
        ?
          <>
            ,<br/>
            for Screening Compounds <b>{discountSC}%</b>
          </>
        : null
      }
    </p>
  );

  const renderWelcomeMessage = () => {
    return (
        <>
          { userInfo && userInfo.userName
              ? (
                  <>
                    <p className="c-header__welcome-message">Welcome, <br/>{userInfo.userName}</p>
                    {(userInfo.discountBB || userInfo.discountSC) ? renderDiscount(userInfo) : null}
                  </>
              )
              : (
                  <>
                    <p className="c-header__welcome-message">Welcome, Guest</p>
                    <a href="/tutorial" className="c-header__tutorial-link">Tutorials</a>
                  </>
              )
          }
        </>
    )
  }

  return (
    <header className="c-header">
      <Section>
        <div className="c-header__body">
          <Logo />
  
          <h1 className="c-header__store-name">Enamine<b>Store</b></h1>
          <div className="c-header__welcome-wrapper">
            {
              isLoading ? <div>Loading...</div> : renderWelcomeMessage()
            }
          </div>

        </div>
      </Section>
    </header>
  )
};

const mapStateToProps = ({ user: { userInfo, isLoading }}) => ({
  userInfo,
  isLoading
});

const mapDispatchToProps = dispatch => ({
  setUserInfo: value => dispatch(setUserInfo(value)),
  setUserLoading: value => dispatch(setUserLoading(value)),
  setCartCount: value => dispatch(setCartCount(value)),
  increaseCartCount: value => dispatch(increaseCartCount(value)),
  setUserCurrency: value => dispatch(setUserCurrency(value)),
  setInfo: value => dispatch(setInfo(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
