import React, { useEffect } from 'react';
import {connect} from 'react-redux';

import classes from '../../utils/classes';
import Section from '../section/section';
import Info from '../info/info';
import { setInfo } from '../../redux/info/info.actions';

const Message = ({ 
  globals,
  data,
  setInfo,
}) => {
  let timer = null;

  const blockClasses = () => classes(
    [
      'c-message',
      {
        ['is-active']: data
      }
    ],
    globals
  );

  useEffect(() => {
    if (data) {
      timer = setTimeout(() => {
        setInfo(null);
      }, 3000);
  
      return () => {
        setInfo(null);
        clearTimeout(timer);
      }
    }
  }, [data]);

  if (data) {
    return (
      <div className={blockClasses()}>
        <Section globals="g-pt4">
          <Info
            text={data.text}
            theme={data.type}
          />
        </Section>
      </div>
    )
  }

  return null;
};

const mapStateToProps = ({ info: { data }}) => ({
  data
});

const mapDispatchToProps = dispatch => ({
  setInfo: value => dispatch(setInfo(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
