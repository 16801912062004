import React from 'react';

import classes from '../../utils/classes';

const Section = ({ children, globals }) => {

  const blockClasses = () => classes(
    [
      'c-section'
    ],
    globals
  );

  return (
    <div className={blockClasses()}>
      {children}
    </div>
  )
}

export default Section;