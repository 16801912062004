import React from 'react';
import {render} from 'react-dom';

import '../scss/main.scss';
import Header from './components/header/header';
import NavHeader from './components/nav-header/nav-header';
import showBodyWhenReactIsReady from './utils/showBodyWhenReactIsReady';

import ReduxProvider from './utils/ReduxProvider';
import runPolyfills from './polyfills';
import Message from './components/message/message';
import TryOutNewStoreContainer from './components/try-out-new-store';

showBodyWhenReactIsReady();
runPolyfills();

const HeaderApp = () => (
  <ReduxProvider>
    <Header />
    <NavHeader />
    <Message />
    <TryOutNewStoreContainer />
  </ReduxProvider>
);

render(<HeaderApp/>, document.getElementById('react-header'));
