import React, { useEffect, useState } from 'react';
import TryOutNewStore from './markup';
import Cookies from 'js-cookie';

const NEW_SITE_HREF = process.env.NEW_SITE_HREF;
const COOKIE_KEY = "user-got-new-store-info";

const TryOutNewStoreContainer = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const activateCookie = () => {
    Cookies.set(COOKIE_KEY, "true", { expires: 365 * 20 });
  }

  const handleCloseModalClick = () => {
    setModalOpen(false);

    activateCookie();
  }

  const handleConfirm = () => {
    setModalOpen(false);

    activateCookie();
  }

  const handleClickTryButton = () => {
     window.location.href = NEW_SITE_HREF + '?redirectFrom=enaminestore';
  }

  useEffect(() => {
    const userGotCoockie = Cookies.get(COOKIE_KEY);

    if (userGotCoockie != "true") {
      setModalOpen(true);
    }
  }, []);
  
  return (
    <TryOutNewStore
      handleCloseModalClick={handleCloseModalClick}
      handleConfirm={handleConfirm}
      modalOpen={modalOpen}
      handleClickTryButton={handleClickTryButton}
    />
  );
};

export default TryOutNewStoreContainer;
