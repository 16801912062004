import React from 'react';
import {connect} from 'react-redux';

import classes from '../../utils/classes';

const CartButton = ({ 
  globals, 
  fullHeight,
  count
}) => {

  const blockClasses = () => classes(
    [
      'c-cart-button',
      {
        'c-cart-button--fullHeight': fullHeight
      }
    ],
    globals
  );

  return (
    <a className={blockClasses()} href="/cart">
      <div className="c-cart-button__text">Cart</div>
      <div className="c-cart-button__counter">{count}</div>
    </a>
  )
};

const mapStateToProps = ({ cart: { count } }) => ({
  count
});

export default connect(mapStateToProps)(CartButton);
