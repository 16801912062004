import navHeaderActions from '../../constants/navHeaderActions';

const registeredUserRightNavData = [
  {
    title: "Profile",
    subItems: [
      {
        title: "View Profile",
        href: "/profile"
      },
      {
        title: "My orders",
        href: "/orders/0"
      },
      {
        title: "Saved Lists",
        action: navHeaderActions.SHOW_SAVED_LIST,
        id: "t-saved-list-button",
      },
      {
        title: "Logout",
        href: "/search.border.logout"
      },
    ]
  },
];

export default registeredUserRightNavData;