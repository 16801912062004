import UserActionTypes from './user.types';

export const setUserLoading = value => ({
  type: UserActionTypes.SET_USER_LOADING,
  payload: value
});

export const setUserInfo = userInfo => ({
  type: UserActionTypes.SET_USER_INFO,
  payload: userInfo
});

export const setUserCurrency = currency => ({
  type: UserActionTypes.SET_USER_CURRENCY,
  payload: currency
});